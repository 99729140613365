import { FloatingWhatsApp } from "react-floating-whatsapp";
import khala from "../media/khala-logo.png";

export default function Whatsapp() {
  return (
    <div>
      <FloatingWhatsApp
        avatar={khala}
        phoneNumber="+96897444122"
        accountName="Khala"
        chatMessage={`Hello,`}
      />
    </div>
  );
}
