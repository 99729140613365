import React from "react";
import "./About.css";

import Quran from "../../media/khala4.png";
import Sultan from "../../media/about-img.png";

import bgImg from "../../media/khala-bg-ft.png";

import Ceo from "../../media/ceo-img.png";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-hero">
        <img src={Quran} alt="somet1" className="about-hero-image" />
      </div>
      <div className="sultan-about">
        <img src={Sultan} alt="somet1" className="sultan-img" />
        <div className="sultan-info">
          <div className="desc-sultan">
            Entrepreneurs who want to establish their own projects, we are
            determined to provide incentives and support to provide more
            opportunities
          </div>
          <div className="spech-sult">
            The speech of Sultan Haitham <br /> ١١/١/٢٠٢٢
          </div>
        </div>
        {/* <img src={bgImg} alt="img" className="bgImg" /> */}
      </div>
      <div className="sultan-about">
        <div>
          <div className="ceo-ttl">
            <div className="vl"></div>
            <h1>Founder and CEO word</h1>
          </div>

          <div className="desc-ceo">
            Though the successive developments taking place in the world and the
            acceleration of the revolution's developments.
            <br />
            <br />
            The technology that produced many amazing electronic services and
            led to lay off hands working in many locations,still there are some
            aspects in the human's life that it isn't possible for technology to
            replace it.
            <br />
            <br /> There is a need for the human element to be ready to complete
            it.
            <br />
            <br /> So for this reason the "Khala" project came to represent a
            thought before it was be an idea. In order to extend its horizons to
            include Many sectors needed by society, whether individually or
            collectively,and We are looking forward to it - God willing
            -Expansion in quantity and quality according to a well-thought-out
            plan that fits the requirements in The current stage and keeps pace
            with the aspirations of the next stage in a framework of material
            interaction and optimism humankind towards a better future for all,
            using the “KHALA - we make it easy for you” methodology.
          </div>
        </div>
        <img src={Ceo} alt="somt3" className="ceo-img" />
      </div>
      <div className="vs-ms-cont">
        <div className="head-vs">
          <img src={bgImg} alt="alt-bg" className="abt-bg" />
          <i className="fa fa-quote-right right1 " aria-hidden="true"></i>
        </div>
        <div className="vs-cont">
          <h2 className="vs-title">VISION</h2>
          {/* <i className="fa fa-quote-left left2" aria-hidden="true"></i> */}
          <p className="ms-cont-txt">
            To be the leading company in providing transportation, delivery,
            support, outsourcing, supervision and regulation services locally.
            Regionally and internationally
          </p>

          {/* <i className="fa fa-quote-right right2" aria-hidden="true"></i> */}
        </div>
        <div className="ms-cont">
          <h2>MISSION</h2>
          {/* <i className="fa fa-quote-left left3" aria-hidden="true"></i> */}
          <p className="ms-cont-txt">
            We strive to be one of the best international companies concerned
            with providing public services by investing the available human,
            natural and technical capabilities in an innovative and creative
            manner
          </p>

          {/* <i className="fa fa-quote-right right3" aria-hidden="true"></i> */}
        </div>
        <div className="head-vs">
          <i className="fa fa-quote-left left1" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default About;
