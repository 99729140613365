import React from "react";
import "./Services.css";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Dialog from "../../components/Dialog";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import transport from "../../media/transport.jpg";
import photograph from "../../media/photograph.jpg";
import move from "../../media/move.jpg";
import transp from "../../media/transp.jpg";
import ttt from "../../media/ttt.jpg";
import car from "../../media/car.jpg";
const Services = () => {
  const [open, setOpen] = React.useState(false);
  const [img, setImg] = React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Navbar />
      <div className="services-component">
        <section>
          <div className="row">
            <div className="section-heading">
              <h2>خدماتنا</h2>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(transport);
                }}
              >
                <div className="icon-wrapper">
                  <i class="fa-solid fa-toolbox"></i>
                </div>
                <h3>خدمة نقل وتوصيل البضائع </h3>
                <p>في محافظة مسقط</p>
              </div>
            </div>
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(photograph);
                }}
              >
                <div className="icon-wrapper">
                  <i class="fa-solid fa-camera"></i>
                </div>
                <h3>خدمة تصوير الافراح والمناسبات</h3>
                <p>في محافظة مسقط</p>
              </div>
            </div>
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(move);
                }}
              >
                <div className="icon-wrapper">
                  <i class="fa-solid fa-truck"></i>
                </div>
                <h3>خدمة نقل الاثاث المنزلي</h3>
                <p>في محافظة مسقط</p>
              </div>
            </div>
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(ttt);
                }}
              >
                <div className="icon-wrapper">
                  <i className="fa-regular fa-bell"></i>
                </div>
                <h3>تنظيم الفاعليات و الاحتفالات المدرسية</h3>
                <p>في محافظة مسقط</p>
              </div>
            </div>
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(car);
                }}
              >
                <div className="icon-wrapper">
                  <i className="fas fa-broom" />
                </div>
                <h3>بيع وشراء السيارات المستعملة</h3>
                <p>احنا نسهلها عليك</p>
              </div>
            </div>
            <div className="column">
              <div
                className="card"
                onClick={(e) => {
                  handleClickOpen();
                  setImg(transp);
                }}
              >
                <div className="icon-wrapper">
                  <i class="fa-solid fa-taxi"></i>
                </div>
                <h3>خدمة نقل الموظفين والطلبة</h3>
                <p>في محافظة مسقط</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <img src={img} className="img_ser"></img>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            اغلاق
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
};

export default Services;
