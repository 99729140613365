import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  let slides = document.getElementsByClassName("slide");
  const auto = true; // Auto scroll
  const intervalTime = 5000;
  let slideInterval;

  const nextSlide = () => {
    const current = document.querySelector(".current");
    current.classList.remove("current");
    if (current.nextElementSibling) {
      current.nextElementSibling.classList.add("current");
    } else {
      slides[0].classList.add("current");
    }
    setTimeout(() => current.classList.remove("current"));
  };

  const prevSlide = () => {
    const current = document.querySelector(".current");
    current.classList.remove("current");
    if (current.previousElementSibling) {
      current.previousElementSibling.classList.add("current");
    } else {
      slides[slides.length - 1].classList.add("current");
    }
    setTimeout(() => current.classList.remove("current"));
  };

  function goNext(e) {
    nextSlide();
    if (auto) {
      clearInterval(slideInterval);
      slideInterval = setInterval(nextSlide, intervalTime);
    }
  }

  function goPrev(e) {
    prevSlide();
    if (auto) {
      clearInterval(slideInterval);
      slideInterval = setInterval(nextSlide, intervalTime);
    }
  }

  if (auto) {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  function handleClick() {
    navigate("services");
  }

  return (
    <div>
      <div className="slider">
        <div className="slide current">
          <div className="content">
            <h3>نقدم لكم الان خدمات متنوعة</h3>
            <p>أعمال التنظيف والصيانة</p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h3>نقدم لكم الان خدمات متنوعة</h3>
            <p> خدمات النقل و توصيل البضائع </p>
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h3>نقدم لكم الان خدمات متنوعة</h3>
            <p>أعمال السباكة المنزلية</p>
            <button
              className="cont-btn"
              type="submit"
              onClick={() => {
                handleClick();
              }}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          id="prev"
          onClick={(e) => {
            goPrev();
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          id="next"
          onClick={(e) => {
            goNext();
          }}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Home;
