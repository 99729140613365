import React, { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../../media/khala-logo.png";
import "./Navbar.css";

const Navbar = () => {
  const navRef = useRef();
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <>
      <div className={color ? "header bg-header" : "header"}>
        <div className="nav" ref={navRef}>
          <button className={color ? "signBtn bg-signBtn" : "signBtn"}>
            طلب تسجيل
          </button>
          <a href="/">الرئيسية</a>
          <a href="/about">من نحن </a>
          <a href="/services">خدماتنا </a>
          <a href="/article">مقالات </a>
          <a href="/contact">إتصل بنا </a>
          <a href="/form"> استمارة اشتراك في الدورة</a>

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </div>
        <img src={Logo} alt="logo" className="khala-logo" />
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </>
  );
};

export default Navbar;
