import React from "react";
import "./Footer.css";
import ImageLogo from "../../media/khala-logo.png";
import { GrLocation } from "react-icons/gr";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import bgImg from "../../media/khala-bg-ft.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <img src={ImageLogo} alt="hi" className="footer-logo" />
      <div className="footer-col">
        <h4>تواصل معنا</h4>
        <ul>
          <li>
            <div>
              <GrLocation className="icon-loc" />
            </div>
            <div>
              P.O Box642, <br />
              Postal Code:132, <br /> Sultanate Of Oman, Muscat
            </div>
          </li>
          <li>
            <div>
              <AiOutlinePhone />
            </div>
            <div>
              +968 2427 2444 <br />
              +968 2427 2444
            </div>
            <br />
          </li>
          <li>
            <div>
              <AiOutlineWhatsApp />
            </div>
            <div>+968 9744 4122</div>
          </li>
          <li>
            <div>
              <AiOutlineMail />
            </div>
            <div>khala_om@gmail.com</div>
          </li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>روابط الموقع</h4>
        <ul>
          <li>
            <a href="/">الرئيسية</a>
          </li>
          <li>
            <a href="/about">من نحن</a>
          </li>
          <li>
            <a href="/services">خدماتنا</a>
          </li>
          <li>
            <a href="/">اخبار و نشاطات</a>
          </li>
          <li>
            <a href="/contact">إتصل بنا</a>
          </li>
          <li>
            <a href="/form">استمارة اشتراك في الدورة</a>
          </li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>اشتراك</h4>
        <div className="social-links">
          <a href="https://mobile.twitter.com/Khala__om">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/khala_om/?utm_medium=copy_link">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://api.whatsapp.com/message/3TURCKG6ZYTCI1?autoload=1&app_absent=0">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCOr8tGrb70M-BJzd2dDso-w">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </div>
      </div>
      <div className="footer-col">
        <img src={bgImg} alt="img" className="bg-fot" />
        <p></p>
      </div>
    </div>
  );
};

export default Footer;
