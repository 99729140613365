import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Whatsapp from "./components/Whatsapp";
import Services from "./pages/Services/Services";
import Form from "./pages/Form/Form";
import Article from "./pages/Article/Article";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Navbar />
        <Home />
        <Footer />
      </>
    ),
  },
  {
    path: "/about",
    element: (
      <>
        <Navbar />
        <About />
        <Footer />
      </>
    ),
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: (
      <>
        <Contact />
      </>
    ),
  },
  {
    path: "/form",
    element: (
      <>
        <Navbar />
        <Form />
        <Footer />
      </>
    ),
  },
  {
    path: "/article",
    element: (
      <>
        <Navbar />
        <Article />
        <Footer />
      </>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <Whatsapp />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
