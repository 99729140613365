import React from "react";
import "./Article.css";

const Form = () => {
  return (
    <>
      <h2 className="article-ttl"> مقالاتنا </h2>
      <div className="article-container">
        <div className="article-content">
          <a
            className="a-cont"
            href="https://asdaaoman.com/%D9%83%D9%80%D9%84-%D8%B4%D9%80%D9%8A%D8%A1-%D9%81%D9%8A-%D9%85%D8%B9%D9%80%D9%84%D9%80%D9%88%D9%85/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-newspaper-o" aria-hidden="true">
              <p>“كـل شـيء في معـلـوم“!!..</p>
            </i>
          </a>
          <a
            className="a-cont"
            href="http://nabdoman.om/7703/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-newspaper-o" aria-hidden="true">
              <p>سرِّي للغاية</p>
            </i>
          </a>
          <a
            className="a-cont"
            href="http://nabdoman.om/4397/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-newspaper-o" aria-hidden="true">
              <p>لضمان الجودة … المكالمات مسجلة !!</p>
            </i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Form;
