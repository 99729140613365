import React from "react";
import "./Form.css";

const Form = () => {
  return (
    <div className="form-container">
      <div className="form-txt"></div>
      <div className="from-content">
        <a
          className="a-cont"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdQ4XUDjbGHK0KqpkHziid_YJ8U7FFkrCENI8ecbEb2-IwEtQ/viewform"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-wpforms" aria-hidden="true">
            <p>استمارة التسجيل في فريق خلا</p>
          </i>
        </a>
        <a
          className="a-cont"
          href="https://docs.google.com/forms/d/1ivQ1Np7b_R4j6Z0KwhQHq1CdfrdlRU-DxcxbeS8zHcA/viewform?edit_requested=true"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-wpforms" aria-hidden="true">
            <p>استمارة التسجيل للمساهمة في مبادرة نقل مرضى غسيل الكلى</p>
          </i>
        </a>
        <a
          className="a-cont"
          href="https://docs.google.com/forms/d/1duYlM3PuZ4j1D5E3k54yNS09tEPizlZnq9kiDRp_ZV4/viewform?edit_requested=true"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-wpforms" aria-hidden="true">
            <p>استمارة تسجيل مرض غسيل الكلى</p>
          </i>
        </a>
      </div>
    </div>
  );
};

export default Form;
