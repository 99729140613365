import React from "react";
import "./Contact.css";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ImgContact from "../../media/hero-contact.png";
import Alert from "../../components/Alert";

const Contact = () => {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [text, setText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const submit = async (event) => {
    if (fullName.length === 0 || email.length === 0 || text.length === 0) {
      setOpen(true);
      setSeverity("error");
      return setMessage("Please fill all fields!");
    }
    event.preventDefault();
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fullName: fullName, email: email, text: text }),
    };
    try {
      const fetchResponse = await fetch(
        `http://localhost:5000/api/contact`,
        settings
      );
      if (fetchResponse.status === 200) {
        event.preventDefault();
        setOpen(true);
        setSeverity("success");
        setMessage("Your Email is sent!");
        setEmail("");
        setFullName("");
        setText("");
      }
      const data = await fetchResponse.json();
      return data;
    } catch (e) {
      return e;
    }
  };
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div>
        <img src={ImgContact} alt="image" className="cont-hero-img" />
      </div>
      <div className="boxes-container-contact">
        <div className="boxes-contact boxes-cont-b1">
          <i class="fa fa-phone faPhone" aria-hidden="true"></i>
          <h4>+968 2427 2444</h4>
        </div>
        <div className="boxes-contact boxes-cont-b2">
          <i class="fa fa-map-marker faPhone" aria-hidden="true"></i>
          <h4>أعمال التنظيف والصيانة</h4>
        </div>
      </div>

      <div className="contact-container">
        <div className="form-container">
          <h3>تواصل معنا</h3>
          <form className="contact-form">
            <label>الاسم الكامل</label>
            <input
              type="text"
              placeholder="ادخل الاسم"
              required
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
            />
            <label>البريد الاكتروني</label>
            <input
              type="email"
              placeholder="ادخل البريد الاكتروني ..."
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label>الرسالة</label>
            <textarea
              type="text"
              cols="30"
              rows="10"
              placeholder="ارسل لنا رسالة ..."
              required
              onChange={(e) => setText(e.target.value)}
              value={text}
            />

            <button onClick={submit} className="send-button">
              ارسل الرسالة
            </button>
          </form>
        </div>
        {/* Map */}
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.9876593415966!2d58.20185431497927!3d23.640612984643678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x40424ae35d85d2b!2zMjPCsDM4JzI2LjIiTiA1OMKwMTInMTQuNiJF!5e0!3m2!1sen!2som!4v1666605616579!5m2!1sen!2som"
            // width="400"
            // height="300"
            // style="border:0"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div>
        <Alert
          message={message}
          open={open}
          setOpen={setOpen}
          severity={severity}
        ></Alert>
        <Footer />
      </div>
    </>
  );
};

//how to center a image?

export default Contact;
